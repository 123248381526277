export const entitiesPaths = {
    //    discounts: "Descuento",
    lines: "Línea",
    categories: "Categoría",
    brands: "Marca",
    products: "Producto",
    custom: "Redirección personalizada",
    undefined: "Sin redirección"
};

export const entitiesUploadImage = {
    reference: "Referencia",
    line: "Línea"
};
