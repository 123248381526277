<template>
    <div class="d-flex flex-wrap">
        <div class="col-12 col-md-6">
            <entity-banner-selector v-model="path.entity" :enabled="enabled" />
        </div>
        <div
            class="col-12 col-md-6"
            :class="path.entity === itemsCurrent.entity ? '' : 'border-warning'"
        >
            <products-search
                v-if="path.entity === 'products'"
                v-model="product"
                label="Producto"
                :default-filters="$data.$defaultFilters"
                :enabled="enabled"
                @input="onSelectProduct"
            />
            <categories-search
                v-else-if="path.entity === 'categories'"
                v-model="category"
                label="Categoría"
                :default-filters="$data.$defaultFilters"
                @input="onSelectCategory"
            />
            <brands-search
                v-else-if="path.entity === 'brands'"
                v-model="brand"
                label="Marca"
                :enabled="enabled"
                :default-filters="$data.$defaultFilters"
                @input="onSelectBrand"
            />
            <lines-search
                v-else-if="path.entity === 'lines'"
                v-model="line"
                label="Línea/Sublínea"
                :enabled="enabled"
                :default-filters="$data.$defaultFilters"
                @input="onSelectLine"
            />
            <vs-input
                v-else-if="path.entity === 'custom'"
                v-model="custom"
                border
                shadow
                autocomplete="off"
                type="text"
                name="line-name"
                label="Escribe el path"
                placeholder="/products"
                class="w-100"
                :disabled="!enabled"
                @blur="onChangeCustom"
            />
        </div>
    </div>
</template>

<script>
import EntityBannerSelector from "@/components/utils/EntityBannerSelector.vue";

export default {
    name: "PathManager",
    components: {
        EntityBannerSelector,
        ProductsSearch: () =>
            import("@/components/products/ProductsSearch.vue"),
        CategoriesSearch: () =>
            import("@/components/categories/CategoriesSearch.vue"),
        BrandsSearch: () => import("@/components/brands/BrandsSearch.vue"),
        LinesSearch: () => import("@/components/lines/LinesSearch.vue")
    },
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        itemsCurrent: {
            type: Object,
            default: () => ({
                entity: "line",
                name: "",
                slug: ""
            }),
            required: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["change"],
    data: () => ({
        $defaultFilters: { active: true },
        path: {
            entity: "undefined",
            name: "",
            slug: ""
        },
        product: { name: "", slug: "", id: "" },
        brand: { name: "", slug: "" },
        category: { name: "", slug: "" },
        line: { name: "", slug: "" },
        custom: ""
    }),
    watch: {
        itemsCurrent(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.itemsCurrent);
    },
    methods: {
        onSelectProduct(item) {
            const aux = {
                entity: "products",
                id: item.id,
                slug: item.slug,
                name: item.name
            };
            this.$emit("change", aux);
        },
        onSelectLine(item) {
            const aux = {
                entity: "lines",
                slug: item.slug,
                name: item.name
            };
            this.$emit("change", aux);
        },
        onSelectCategory(item) {
            const aux = {
                entity: "categories",
                slug: item.slug,
                name: item.name
            };
            this.$emit("change", aux);
        },
        onSelectBrand(item) {
            const aux = {
                entity: "brands",
                slug: item.slug,
                name: item.name
            };
            this.$emit("change", aux);
        },
        onChangeCustom() {
            const aux = {
                entity: "custom",
                slug: this.custom,
                name: this.custom
            };
            this.$emit("change", aux);
        },
        setInput(value) {
            this.path = value;
            if (value.entity === "lines") {
                this.line = {
                    name: value.name,
                    slug: value.slug
                };
            } else if (value.entity === "brands") {
                this.brand = {
                    name: value.name,
                    slug: value.slug
                };
            } else if (value.entity === "products") {
                this.product = {
                    id: value.id,
                    name: value.name,
                    slug: value.slug
                };
            } else if (value.entity === "categories") {
                this.category = {
                    name: value.name,
                    slug: value.slug
                };
            } else if (value.entity === "custom") {
                this.custom = value.slug;
            }
        }
    }
};
</script>
