<template>
    <div>
        <div v-if="enabled" class="d-flex">
            <span v-if="label"> {{ label }}&nbsp; </span>
            <vs-switch
                v-model="activeComponent"
                class="bg-danger w-28"
                @input="onInput"
            >
                <template #on>
                    <small> {{ onLabel ? onLabel : "Activado" }} </small>
                </template>
                <template #off>
                    <small> {{ offLabel ? offLabel : "Desactivado" }} </small>
                </template>
            </vs-switch>
        </div>

        <div v-else class="d-flex align-items-center">
            <span> {{ onLabel }} &nbsp;</span>
            <div
                v-if="active"
                class="rounded-circle len-4 bg-success d-flex justify-content-center align-items-center"
            >
                <span>
                    {{ weight ? weight : "" }}
                </span>
            </div>
            <div v-else class="rounded-circle len-3 bg-danger"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ActiveSelector",
    model: {
        prop: "active",
        event: "change"
    },
    props: {
        active: {
            default: () => false,
            type: Boolean,
            require: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        },
        weight: {
            default: () => 0,
            type: Number,
            required: false
        },
        label: {
            default: () => "",
            type: String,
            required: false
        },
        onLabel: {
            default: () => "Activado",
            type: String,
            required: false
        },
        offLabel: {
            default: () => "Desactivado",
            type: String,
            required: false
        }
    },
    emits: ["change"],
    data: () => ({
        activeComponent: false
    }),
    watch: {
        active(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.active);
    },
    methods: {
        setInput(value) {
            this.activeComponent = value;
        },
        onInput() {
            this.$emit("change", this.activeComponent);
        }
    }
};
</script>
