<template>
    <div>
        <div class="d-flex flex-column">
            <div
                id="title-general"
                class="d-flex flex-column flex-md-row justify-content-between align-items-center"
            >
                <div class="d-flex justify-content-between align-items-center">
                    <router-link
                        to="/app/banners"
                        class="rounded-circle bg-info d-flex justify-content-center align-items-center p-2"
                    >
                        <span>🏠</span>
                    </router-link>
                    <h1 v-if="id" class="h2">
                        &nbsp;Detalle de banner #{{ id }} /
                        <span class="text-primary">
                            {{ name }}
                        </span>
                    </h1>
                    <h1 v-else class="h2">&nbsp;Nuevo banner</h1>
                </div>

                <vs-switch
                    v-if="$ability.hasScope('banner:Update') && id"
                    v-model="enabledUpdate"
                    :class="enabledUpdate ? 'bg-primary' : 'bg-secondary'"
                >
                    🖊
                </vs-switch>
            </div>
            <span class="mt-3">
                Los detalles del banner determinan donde va a ser mostrado el
                banner, que imagen debe tener y el lugar al que redirige.
            </span>
        </div>
        <div id="banner-features">
            <div class="d-flex flex-wrap">
                <div
                    class="col-12 col-md-6 mt-3 d-flex justify-content-center align-items-center"
                >
                    <active-selector
                        v-model="itemComponent.active"
                        :enabled="enabledUpdate"
                        :weight="parseInt(itemComponent.order)"
                        on-label="Publicado"
                        off-label="Oculto"
                    />
                </div>
                <div
                    v-if="id"
                    class="col-12 col-md-6 mt-3 p-0 d-flex justify-content-around"
                >
                    <small class="text-left">
                        📆💾 {{ itemComponent.createdAt | date }}
                    </small>
                    <small class="text-left">
                        📆✏ {{ itemComponent.updatedAt | date }}
                    </small>
                </div>
            </div>

            <div class="d-flex flex-wrap">
                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="itemComponent.name"
                        :state="stateEnabled"
                        :shadow="!enabledUpdate"
                        label="Nombre"
                        class="opacity-false"
                        name="BannerName"
                        placeholder="Banner principal"
                        :disabled="!enabledUpdate"
                        @blur="blurName"
                    >
                        <template
                            v-if="!isValidName & introValidators.name"
                            #message-danger
                        >
                            Nombre de banner invalido
                        </template>
                    </vs-input>
                </div>
                <div class="col-12 col-md-6 mt-5">
                    <subline-selector
                        v-model="itemComponent.line"
                        add-root
                        label="Línea donde se mostrará el banner"
                        :enabled="enabledUpdate"
                    />
                </div>
            </div>

            <div>
                <path-manager
                    v-model="itemComponent.path"
                    class="mt-4"
                    :enabled="enabledUpdate"
                />
            </div>

            <div class="d-flex flex-wrap">
                <div class="col-12 mt-5">
                    <image-manage
                        v-model="itemComponent.image"
                        :enabled="enabledUpdate"
                        message="Solo puedes usar para banners imágenes responsive"
                        :default-filters="{ size: 'tripleImage' }"
                    />
                </div>
            </div>

            <div class="d-flex flex-wrap h-10 mt-3">
                <div
                    v-if="enabledUpdate"
                    class="col-12 d-flex justify-content-center"
                >
                    <vs-button
                        v-if="!id"
                        size="large"
                        @click="createItemComponent"
                    >
                        Crear banner
                    </vs-button>
                    <vs-button v-else size="large" @click="updateItemComponent">
                        Actualizar banner
                    </vs-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { nameRegex } from "@/common/lib/regex";

import SublineSelector from "@/components/lines/SublineSelector.vue";
import ActiveSelector from "@/components/utils/ActiveSelector.vue";
import ImageManage from "@/components/utils/ImageManage.vue";
import PathManager from "@/components/utils/PathManager.vue";

export default {
    name: "BannersDetails",
    components: {
        PathManager,
        SublineSelector,
        ImageManage,
        ActiveSelector
    },
    data: () => ({
        itemComponent: {
            name: "",
            order: 1,
            active: false,
            path: { id: "", name: "", entity: "undefined", slug: "" },
            image: { id: 0 },
            line: { slug: "null", name: "" }
        },
        introValidators: { name: false },
        enabledUpdate: false,
        loaderInstance: null,
        isLoading: false,
        loadInfo: false,
        name: "",
        id: 0
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor", "stateInputDark"]),
        isValidName() {
            return nameRegex.test(this.itemComponent.name);
        },
        stateEnabled() {
            return this.enabledUpdate ? this.stateInputDark : "";
        }
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if ("id" in this.$route.params) {
            if (!(await verifyPageScope("banner:Create", "/app/banners")))
                return;
            const id = Number(this.$route.params.id);
            this.id = id;
            await this.getBaseItem(id);
        } else if ("idClone" in this.$route.params) {
            if (!(await verifyPageScope("banner:Create", "/app/banners")))
                return;
            this.id = 0;
            this.enabledUpdate = true;
            const idTmp = Number(this.$route.params.idClone);
            await this.getBaseItem(idTmp);
            this.itemComponent = {
                ...this.itemComponent,
                name: `${this.itemComponent.name} CLONE`,
                active: false
            };
            this.$swal.fire({
                background: this.backgroundColor,
                title: "Estas clonando un banner",
                icon: "info",
                showCloseButton: true
            });
        } else {
            if (!(await verifyPageScope("banner:Get", "/app/banners"))) return;
            this.enabledUpdate = true;
            this.loadInfo = true;
        }
    },
    methods: {
        ...mapActions("banners", [
            "getBannerById",
            "createBanner",
            "updateBanner"
        ]),
        blurName() {
            this.introValidators.name = true;
        },
        async getBaseItem(id) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "waves" });
            try {
                const banner = await this.getBannerById({ id });
                let line = banner.line;
                if (!banner.line) {
                    line = { slug: "null", name: "Root" };
                }
                this.itemComponent = { ...banner, line };
                this.name = this.itemComponent.name;
                this.loadInfo = true;
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.loaderInstance.close();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                if (error.statusCode === 404) {
                    this.$router.push("/app/banners");
                }
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async updateItemComponent() {
            if (!this.validateData()) {
                return;
            }
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "waves" });
            try {
                await this.updateBanner(this.getDataSend());
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        },
        async createItemComponent() {
            if (!this.validateData()) {
                return;
            }
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "waves" });
            try {
                const data = await this.createBanner(this.getDataSend());
                this.loaderInstance.close();
                this.itemComponent = { ...data, ...this.itemComponent };
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Creación exitosa",
                    text: `Id: ${data.id}`,
                    icon: "success",
                    showDenyButton: true,
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonColor: "#02672D",
                    cancelButtonColor: "#253C99",
                    denyButtonColor: "#f07325",
                    confirmButtonText: "Ver detalle",
                    denyButtonText: "Ir a lista de banners",
                    cancelButtonText: "Nuevo banner"
                });
                if (dialogResult.isConfirmed) {
                    this.id = data.id;
                    this.$router.push(`/app/banners/detail/${this.id}`);
                }
                if (dialogResult.isDenied) {
                    this.$router.push("/app/banners/");
                }
                if (dialogResult.isDismissed) {
                    this.introValidators = {
                        slug: false,
                        name: false,
                        shortDescription: false
                    };
                    this.itemComponent = {
                        name: "",
                        description: "",
                        slug: "",
                        shortDescription: "",
                        keywords: [],
                        images: []
                    };
                    this.images = [];
                }
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        validateData() {
            this.introValidators = {
                name: true
            };

            const toComplete = [];

            if (!this.isValidName) {
                toComplete.push("Nombre");
            }

            if (!this.itemComponent.image.id) {
                toComplete.push("Imagen");
            }

            if (!this.itemComponent.path) {
                toComplete.push("Path");
            }

            if (toComplete.length) {
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Banner invalido",
                    text: `Revisa por favor que todo este completo. Valida los siguientes campos: ${toComplete.join(
                        ", "
                    )}`,
                    icon: "info"
                });
                return false;
            }
            return true;
        },
        getDataSend() {
            const lineSlug = this.itemComponent.line.slug ?? "null";
            return {
                ...this.itemComponent,
                lineSlug: lineSlug !== "null" ? lineSlug : null,
                imageId: this.itemComponent.image.id
            };
        }
    }
};
</script>
