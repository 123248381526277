<template>
    <div class="w-100">
        <vs-select
            v-if="enabled"
            v-model="option"
            class="w-100 mw-100 border-custom"
            :state="stateInputDark"
            label="Lugar a donde redirigirá el banner"
            @input="onInput"
        >
            <vs-option
                v-for="(item, key) in $data.$enabledOptions"
                :key="key"
                :label="item"
                :value="key"
            >
                {{ item }}
            </vs-option>
        </vs-select>
        <div v-else>
            <vs-input
                v-model="value"
                shadow
                class="w-100 opacity-false"
                label-placeholder="Entidad"
                disabled
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import { entitiesPaths } from "@/common/fields/entities-paths";

export default {
    name: "EntityBannerSelector",
    model: {
        prop: "itemCurrent",
        event: "input"
    },
    props: {
        itemCurrent: {
            type: String,
            default: () => "undefined",
            required: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["input"],
    data: () => ({
        option: "custom",
        value: Object.values(entitiesPaths)[0],
        $enabledOptions: entitiesPaths
    }),
    computed: {
        ...mapGetters("control", ["stateInputDark"])
    },
    watch: {
        itemCurrent(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.itemCurrent);
    },
    methods: {
        onInput(value) {
            this.value = entitiesPaths[value];
            this.$emit("input", value);
        },
        setInput(value) {
            this.option = value;
            this.value = entitiesPaths[value];
        }
    }
};
</script>
